<template>
  <div class="expand-row relative" >
    <vue-select
      v-if="isFreeVersionWebSite"
      @input="changedFreeGroup($event, item)"
      :options="item.options"
      v-model="item.selectedOptions"
      :disabled="item.options.length <= 1"
      :clearable="false"
      class="select-group-for-chart"
    />
    <div class="row">
      <div class="col">
        <div class="user-result">
          <div class="chart-wrapper">
            <div class="text-center position-title-chart">
              Contacts versus Self
            </div>
            <ChartConsistencyOverall :data="item.chartBar" />
          </div>
          <div class="chart-wrapper" id="bubble-chart">
            <div class="text-center position-title-chart">
              Comparison by TRAIT
              {{ isFreeVersionWebSite ? " - " + item.selectedOptions : "" }}
            </div>
            <BubbleChart
              :data="item.chartBar"
              :subGroup="subGroup"
              :showLabels="!!item.allType.userType"
              @click-to-character="
                helpFunction.setChosenGroupCharacteristic(
                  $event,
                  myResultsScoreData,
                  othersResultsScoreData,
                  chooseOtherResult
                )
              "
            />
          </div>
          <div class="chart-wrapper" id="chart-compare">
            <div class="text-center position-title-chart">
              Comparison by TYPE
              {{ isFreeVersionWebSite ? " - " + item.selectedOptions : "" }}
            </div>
            <ChartCompare
              :data="item.chartCompare"
              :nameRespondents="selectedGroup.groupName"
              :respondentsCount="item.countOther"
              :adminPanel="true"
            />
          </div>
          <div class="chart-wrapper">
            <div class="text-center position-title-chart">
              Distribution by TYPE
              {{ isFreeVersionWebSite ? " - " + item.selectedOptions : "" }}
            </div>
            <DepartmentSummaryChart
              v-if="item.departmentSummary"
              :respondentsCount="item.countOther"
              :data="item.departmentSummary"
              :nameRespondents="selectedGroup.groupName"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChartConsistencyOverall from '@components/Admin/Charts/ChartConsistencyOverall.vue';
import DepartmentSummaryChart from '@components/Admin/Charts/DepartmentSummaryChart.vue';
import BubbleChart from '@components/BubbleChart/BubbleChart.vue';
import ChartCompare from '@components/Charts/ChartCompare.vue';
import isFreeVersion from '@helpers/func';
import helpFunction from '@helpers/helpFuction';

export default {
  components: {
    BubbleChart,
    ChartConsistencyOverall,
    ChartCompare,
    DepartmentSummaryChart,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    subGroup: {
      default: false,
    },
    selectedGroup: {
      type: Array,
      default: () => [],
    },
    myResultsScoreData: Object,
    othersResultsScoreData: Object,
  },
  data() {
    return {
      helpFunction,
    };
  },
  computed: {
    isFreeVersionWebSite() {
      return isFreeVersion();
    },
  },
  methods: {
    changedFreeGroup(val, item) {
      this.$emit('changedFreeGroup', val, item);
    },
    chooseOtherResult(event, nameResult, subGroup, colorU1, colorU2, borderColorU1, borderColorU2) {
      this.$emit('chooseOtherResult', {
        event,
        nameResult,
        subGroup,
        colorU1,
        colorU2,
        borderColorU1,
        borderColorU2,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.expand-row {
  .bubble-chart, .chart-compare {
    height: 360px;
    width: 350px;
    background-color: white;
    display: inline-block;
    border: 1px solid #ccc;
  }
  .bubble-chart {
    position: relative;
    margin: 9px 30px 43px 17px;
    padding: 40px 7px 0 7px;
    .label-bubble-chart {
      left: 37%;
    }
  }
  .chart-compare {
    margin-top: 10px;
    margin-bottom: 23px;
    margin-left: 5%;
    padding-bottom: 22px;
    .name-label-chart-left {
      top: 175px !important;
      left: 4px;
    }
    .name-label-chart-top {
      top: 20px;
      right: 3px;
    }
    .name-label-chart-right {
      top: 175px !important;
      right: 4px;
    }
    .name-label-chart-bottom {
      bottom: 0;
    }
  }
  .user-result {
    display: flex;
    flex: 1;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .select-group-for-chart {
    width: 15%;
    min-width: 107px;
    top: 5px;
    left: 43%;
    div {
      background-color: white;
    }
  }
  .chart-wrapper {
    width: 410px;
    height: 420px;
    border: 1px solid #ccc;
    padding: 0 7px 0 7px;
    margin: 19px 5px 0 5px;
    background-color: white;
  }
}
</style>
