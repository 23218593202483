<template>
  <div class="admin-dashboard">
    <h1 class="h4 text-center mb-4">Dashboard</h1>
    <ButtonToMenu />
    <AdminDashboardTable
      :loading="loadingTable"
      :dashboardData="dashboardData"
      :pageCount="pageCount"
      :subGroup="subGroup"
      :selectedGroup="selectedGroup"
      :myResultsScoreData="myResultsScoreData"
      :othersResultsScoreData="othersResultsScoreData"
      @chooseOtherResult="chooseOtherResult"
      @changedFreeGroup="changedFreeGroup"
      @changeGroup="changeGroup"
      @getDataDashboard="getDataDashboard"
      @changeDashboardData="changeDashboardData"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import helpFunction from '@helpers/helpFuction';
import AdminDashboardTable from '@modules/AdminDashboard/AdminDashboardTable.vue';
import constants from '@constants/index';
import isFreeVersion from '@helpers/func';

export default {
  name: 'adminDashboard',
  components: {
    AdminDashboardTable,
  },
  data: () => ({
    myResultsScoreData: {
      opensResult: '',
    },
    othersResultsScoreData: {
      opensResult: '',
    },
    subGroup: false,
    pageCount: 0,
    dashboardData: [],
    loadingTable: true,
    departmentSummaryOtherResult: [],
    selectedGroup: [],
    otherCoordinate: [],
  }),
  async mounted() {
    this.pageCount = await this.$api.admin.countUsers();
    await this.getDataDashboard();
  },
  computed: {
    isFreeVersionWebSite() {
      return isFreeVersion();
    },
  },
  methods: {
    changeDashboardData(dashboardData) {
      this.dashboardData = dashboardData;
    },
    async getDataDashboard(page = 1, sortField = '', sortDesc = false) {
      this.loadingTable = true;
      this.dashboardData = [];
      const result = await this.$api.admin.getInfoDashboard(
        process.env.QUESTIONNAIRE_ID,
        page - 1,
        sortField,
        sortDesc,
      );
      this.setTableData(result);
      this.loadingTable = false;
    },
    setTableData(response) {
      response.forEach((x) => {
        const {
          userType,
          otherType,
          radarData,
          chartCompare,
          departmentSummary,
        } = this.calculateDataForChart(x.result, x.otherResult, 'Contacts');
        const d = new Date(x.createdDate);
        const createdDate = `${`0${d.getDate()}`.slice(-2)}-${`0${d.getMonth() + 1}`.slice(-2)}-${d.getFullYear()}`;
        const countConnection = x.otherResult.Contacts
          ? x.otherResult.Contacts.numberConnection : 0;
        const options = Object.keys(x.otherResult).map(y => ({
          label: y === 'Contacts' ? 'Total' : (y.charAt(0).toUpperCase() + y.slice(1)),
          value: y,
        }));

        this.dashboardData.push({
          userId: x.id,
          employee: x.employee,
          department: x.department,
          manager: x.manager,
          countOther: countConnection,
          scoreOverall: `${x.scoreOverall.generalPercent}%`,
          role: x.role,
          number_connections: countConnection,
          created_date: createdDate,
          type: userType,
          allType: { userType, otherType },
          preferred_reviewer_ranking: x.reviewerRanking,
          chartBar: radarData,
          chartCompare,
          departmentSummary,
          itemsSelectGroups: x.userGroups,
          options,
          selectedOptions: 'Total',
          allOtherResult: x.otherResult,
          allSelfResult: x.result,
        });
        this.selectedGroup.push(x.userGroups[0]);
        this.loadingTable = false;
        // this.$forceUpdate();
      });
    },
    calculateDataForChart(result, otherResult, groupNameFreeVersion) {
      this.resetData();
      const { userType, ...selfData } = this.getSelfData(result);
      const { otherType, ...otherData } = this.getOtherData(otherResult, groupNameFreeVersion);
      const chartCompare = this.chartOptionsBar(userType, otherType);
      const departmentSummary = this.chartOptionsBarDepartmentSummary();
      return {
        userType,
        otherType,
        radarData: [selfData, otherData],
        chartCompare,
        departmentSummary,
      };
    },
    getSelfData(result) {
      let value = [];
      let userType = '';
      if (result && result.mainResult) {
        // eslint-disable-next-line prefer-destructuring
        userType = helpFunction.Coordinates(result.mainResult)[2];
        value = Object.values(result.mainResult.split(/(?=[-+])/));
        this.selfCoordinate = helpFunction.Coordinates(result.mainResult);
        this.myResultsScoreData = result;
      }
      return {
        value,
        type: 'bar',
        areaStyle: {
          color: '#7811c9',
          colorHover: '#a111ff',
        },
        itemColor: {
          borderColor: '#5e119f',
          color: '#9C11F2',
        },
        name: 'Self',
        userType,
      };
    },
    getOtherData(otherResult, groupName) {
      let value = [];
      let otherType = '';
      if (otherResult && otherResult[groupName] && otherResult[groupName].mainResult) {
        // eslint-disable-next-line prefer-destructuring
        otherType = helpFunction.Coordinates(otherResult[groupName].mainResult)[2];
        value = Object.values(otherResult[groupName].mainResult.split(/(?=[-+])/));
        this.otherCoordinate = helpFunction.Coordinates(otherResult[groupName].mainResult);
        this.othersResultsScoreData = otherResult[groupName];
        if (otherResult[groupName].eachU1Result) {
          otherResult[groupName].eachU1Result.forEach((element) => {
            this.departmentSummaryOtherResult.push(helpFunction.Coordinates(element));
          });
        }
      }
      return {
        value,
        type: 'bar',
        areaStyle: {
          color: '#ff5151',
          colorHover: 'rgba(255,81,81,0.73)',
        },
        name: this.isFreeVersionWebSite && groupName === 'Contacts' ? 'Total' : groupName,
        itemColor: {
          borderColor: '#bf4545',
          color: '#ff5151',
        },
        otherType,
      };
    },
    chartOptionsBar(type, otherType) {
      const chartCompare = [];
      Object.values(constants.cards).forEach((value) => {
        chartCompare.push({
          value: [],
          type: 'GUESS',
          data: [...value.value, value.title, value.showText],
        });
      });
      if (this.selfCoordinate[0] || this.selfCoordinate[1]) {
        chartCompare.push(
          {
            value: [],
            type: 'YOU_ARE',
            data: [this.selfCoordinate[0], this.selfCoordinate[1], `Self-scored - \n${type}`],
          },
        );
      }
      if (this.otherCoordinate) {
        chartCompare.push({
          value: [],
          type: 'COLLEAGUE',
          data: [this.otherCoordinate[0], this.otherCoordinate[1], `Contacts scored - \n${otherType}`],
        });
      }
      return chartCompare;
    },
    chartOptionsBarDepartmentSummary() {
      let departmentSummary = [];
      Object.values(constants.cards).forEach((value) => {
        departmentSummary.push({
          value: [],
          type: 'GUESS',
          data: [...value.value, value.title, value.showText],
        });
      });
      const counts = {};
      this.departmentSummaryOtherResult.forEach((x) => {
        counts[x[2]] = (counts[x[2]] || 0) + 1;
        departmentSummary.push({
          value: [],
          type: 'EACH_COLLEAGUES',
          data: [x[0], x[1], x[2], counts[x[2]]],
        });
      });
      let countTheSameObjects = 0;
      // filter by type 'EACH_COLLEAGUES'
      departmentSummary = departmentSummary.filter((elt) => {
        if (elt.type === 'COLLEAGUE' && countTheSameObjects === 0) {
          countTheSameObjects += 1;
          return true;
        }
        return !(elt.type === 'COLLEAGUE' && countTheSameObjects !== 0);
      });
      return departmentSummary;
    },
    resetData() {
      this.selfCoordinate = [];
      this.otherCoordinate = [];
      this.departmentSummaryOtherResult = [];
    },
    chooseOtherResult(result) {
      this.resetData();
      const chartBar = [
        {
          value: this.myResultsScoreData[result.nameResult]
            ? Object.values(this.myResultsScoreData[result.nameResult].split(/(?=[-+])/))
            : [],
          type: 'bar',
          areaStyle: { color: '#7811c9', colorHover: '#a111ff' },
          itemColor: {
            borderColor: result.borderColorU1 || '#5e119f',
            color: result.colorU1 || '#9C11F2',
          },
          name: 'Self',
        },
        {
          value: this.othersResultsScoreData[result.nameResult]
            ? Object.values(this.othersResultsScoreData[result.nameResult].split(/(?=[-+])/))
            : [],
          type: 'bar',
          areaStyle: { color: '#ff5151', colorHover: 'rgba(255,81,81,0.73)' },
          name: this.isFreeVersionWebSite ? 'Total' : 'Contacts',
          itemColor: {
            borderColor: result.borderColorU2 || '#bf4545',
            color: result.colorU2 || '#ff5151',
          },
        },
      ];
      this.dashboardData = this.dashboardData.map((x) => {
        if (x.userId === result.expanded[0].userId) {
          return { ...x, chartBar };
        }
        return x;
      });
      this.subGroup = result.subGroup;
    },
    changedFreeGroup(val, item) {
      this.dashboardData = this.dashboardData.map((x) => {
        if (x.userId === item.userId) {
          const {
            radarData,
            chartCompare,
            departmentSummary,
          } = this.calculateDataForChart(x.allSelfResult, x.allOtherResult, val.value);
          return {
            ...x,
            countOther: x.allOtherResult[val.value].numberConnection,
            chartBar: radarData,
            chartCompare,
            departmentSummary,
            selectedOptions: val.label,
          };
        }
        return x;
      });
    },
    async changeGroup(groupId, userId) {
      const data = await this.$api.admin.getInfoByGroup(userId, groupId);
      const { radarData, chartCompare, departmentSummary } = this.calculateDataForChart(data.result, data.otherResult, 'Total');
      this.dashboardData = this.dashboardData.map((x) => {
        if (x.userId === userId) {
          const countConnection = data.otherResult.Contacts
            ? data.otherResult.Contacts.numberConnection
            : 0;
          return {
            ...x,
            countOther: countConnection,
            chartBar: radarData,
            chartCompare,
            departmentSummary,
            itemsSelectGroups: x.itemsSelectGroups,
            selectedOptions: 'Total',
            allOtherResult: data.otherResult,
            allSelfResult: data.result,
          };
        }
        return x;
      });
    },
  },
};
</script>
