var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-dashboard__table v-card-table"},[_c('LoadingAllScreen',{attrs:{"is-loading":_vm.createPdf,"scroll-lock":false},on:{"update:isLoading":function($event){_vm.createPdf=$event},"update:is-loading":function($event){_vm.createPdf=$event}}}),_c('InputSearch',{model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-app',[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"userId","headers":_vm.headers,"items":_vm.dashboardData,"page":_vm.page,"mobile-breakpoint":"1","show-expand":"","loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"loading-text":"Loading... Please wait","hide-default-footer":"","single-expand":"","custom-sort":_vm.customSort,"expanded":_vm.expanded,"search":_vm.searchText},on:{"update:page":function($event){_vm.page=$event},"update:expanded":function($event){_vm.expanded=$event},"page-count":function($event){return _vm.calculateCountPage()}},scopedSlots:_vm._u([{key:"header.type",fn:function(){return [_c('th',[_c('v-select',{staticClass:"select-type",attrs:{"items":_vm.headerChangeType,"item-text":"text","item-value":"value","solo":""},on:{"change":function (type) { return _vm.changedTypeInTable(type); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"select-type-text"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.selectedChangeType[0]),callback:function ($$v) {_vm.$set(_vm.selectedChangeType, 0, $$v)},expression:"selectedChangeType[0]"}})],1)]},proxy:true},{key:"item.selectGroup",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-select',{staticClass:"select-group",attrs:{"items":item.itemsSelectGroups,"item-text":"groupName","item-value":"id"},on:{"change":function (groupId) { return _vm.changeGroup(groupId, item.userId); }},model:{value:(_vm.selectedGroup[index]),callback:function ($$v) {_vm.$set(_vm.selectedGroup, index, $$v)},expression:"selectedGroup[index]"}})]}},{key:"item.save_pdf_file",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"disabled":_vm.createPdf || !item.allType.userType,"color":"purple"},on:{"click":function($event){return _vm.saveCSVFile(item)}}},[_c('span',{staticClass:"button-save-pdf-text"},[_vm._v("Save to PDF")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ExpandTableRow',{attrs:{"item":item,"subGroup":_vm.subGroup,"selectedGroup":_vm.selectedGroup,"myResultsScoreData":_vm.myResultsScoreData,"othersResultsScoreData":_vm.othersResultsScoreData},on:{"changedFreeGroup":_vm.changedFreeGroup,"chooseOtherResult":_vm.chooseOtherResult}})],1)]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"disabled":_vm.loading,"length":_vm.calculateCountPage(),"total-visible":10},on:{"input":_vm.pageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }